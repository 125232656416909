import { Clue, clueClass } from "./clue";
import { LetterBindings } from "./Game";

interface KeyboardProps {
  layout: string;
  letterInfo: Map<string, [Clue, number]>;
  bindings: LetterBindings;
  letterPairStyle: string;
  letterSort: string;
  onKey: (key: string) => void;
}

function stylize(label: string, style: string, sort: string): string {
  if(sort === "ZA") {
    return stylize(label.split("").reverse().join(""), style, "AZ")
  }
  if (style === "AZ") {
    return label[0].toUpperCase() + label[1].toUpperCase();
  }
  if (style === "az") {
    return label[0].toLowerCase() + label[1].toLowerCase();
  }
  if (style === "Az") {
    return label[0].toUpperCase() + label[1].toLowerCase();
  }
  if (style === "aZ") {
    return label[0].toLowerCase() + label[1].toUpperCase();
  }
  return label;
}

export function Keyboard(props: KeyboardProps) {
  const keyboard = props.layout
    .split("-")
    .map((row) =>
      row
        .split("")
        .map((key) => key
          .replace("B", "Backspace")
          .replace("E", "Enter")
          .replace("S", "Style")
          .replace("D","Delete")
          .replace("L","ArrowLeft")
          .replace("R","ArrowRight")
          .replace("_","SpaceBar")
          .replace("Z", "Sort"))
    );

  let bindings = props.bindings.sort(
    (a, b) => props.letterSort === "AZ" ? a[0].localeCompare(b[0]) : a[1].localeCompare(b[1])
  );
  var c = 0;
  return (
    <div className="Game-keyboard" aria-hidden="true">
      {keyboard.map((row, i) => (
        <div key={i} className="Game-keyboard-row">
          {row.map((label, j) => {
            let className = "Game-keyboard-button";
            let typedKey = label;
            if (label === 'x') {
              label = bindings[c];
              c++;
              typedKey = label[0].toLowerCase();
              className += " Letter-pair";
              const clue = props.letterInfo.get(label.toLowerCase());
              if (clue !== undefined) {
                className += " " + clueClass(clue);
              }  
              label = stylize(label, props.letterPairStyle, props.letterSort);
            } else {
              className += " Functional-key";
              label = label
                .replace("Backspace", "⌫")
                .replace("Style", "⇧")
                .replace("Sort", "⇄")
                .replace("SpaceBar", "  ")
                .replace("Delete", "Del")
                .replace("ArrowLeft", "←")
                .replace("ArrowRight", "→");
            }
            return (
              <button
                tabIndex={-1}
                key={j}
                className={className}
                onClick={() => {
                  props.onKey(typedKey);
                }}
              >
                {label}
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
}
