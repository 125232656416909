import { LetterBindings, boundPairs, boundWord } from "./Game";

export enum Clue {
  Absent,
  Elsewhere,
  Correct,
}

export interface CluedLetterPair {
  clue?: Clue;
  letterPair: string;
}

export function clue(word: string, bindings: LetterBindings, target: string): CluedLetterPair[] {
  let wordPairs = boundPairs(word);
  let targetPairs = boundPairs(boundWord(target, bindings));
  let elusive: string[] = [];
  targetPairs.forEach((letterPair, i) => {
    if (wordPairs[i] !== letterPair) {
      elusive.push(letterPair);
    }
  });
  return wordPairs.map((letterPair, i) => {
    let j: number;
    if (targetPairs[i] === letterPair) {
      return { clue: Clue.Correct, letterPair };
    } else if ((j = elusive.indexOf(letterPair)) > -1) {
      // "use it up" so we don't clue at it twice
      elusive[j] = "";
      return { clue: Clue.Elsewhere, letterPair };
    } else {
      return { clue: Clue.Absent, letterPair };
    }
  });
}

export function clueClass(clue: [Clue, number]): string {
  const countClass = clue[1] > 0 ? " letter-multi-" + clue[1] : "";
  if (clue[0] === Clue.Absent) {
    return "letter-absent";
  } else if (clue[0] === Clue.Elsewhere) {
    return "letter-elsewhere" + countClass;
  } else {
    return "letter-correct" + countClass;
  }
}

export function clueWord(clue: Clue): string {
  if (clue === Clue.Absent) {
    return "no";
  } else if (clue === Clue.Elsewhere) {
    return "elsewhere";
  } else {
    return "correct";
  }
}

export function describeClue(clue: CluedLetterPair[]): string {
  return clue
    .map(({ letterPair, clue }) => letterPair.toUpperCase() + " " + clueWord(clue!))
    .join(", ");
}
