import { useEffect, useRef, useState, useMemo } from "react";
import { Row, RowState } from "./Row";
import { Clue, clue, CluedLetterPair, describeClue } from "./clue";
import { Keyboard } from "./Keyboard";
import { PopUp } from "./PopUp"
import {
  gameName,
  pick,
  speak,
  practice,
  dayNum,
  todayDayNum,
  cheat,
  makeRandom,
  urlParam,
  isDev,
  needResetPractice,
  currentSeed,
  day1Date
} from "./util";

import { hardCodedPuzzles } from "./hardcoded";
import { hardCodedPractice } from "./hardcoded_practice";
import { Day, RawStats } from "./Stats"
import { readOnly, serializeStorage } from "./App";

import cheatyface from "./cheatyface.json";

import real_full_dictionary from "./dictionary_5.json";
import real_full_targets from "./targets_5.json";

const full_dictionary = real_full_dictionary;
const full_targets = real_full_targets;

/*
import real_full_dictionary from "./dictionary.json";
import real_full_targets from "./targets.json";
import fives_dictionary from "./dictionary_5.json";
import fives_targets from "./targets_5.json";
import fours_dictionary from "./dictionary_4.json";
import fours_targets from "./targets_4.json";
import sixes_dictionary from "./dictionary_6.json";
import sixes_targets from "./targets_6.json";
const full_dictionary = real_full_dictionary.filter((word: string) => word.length >= 4 && word.length <= 6);
const full_targets = real_full_targets.filter((word: string) => word.length >= 4 && word.length <= 6);
*/

export enum GameState {
  Playing,
  Won,
  Lost,
}

declare const GoatEvent: Function;
declare const checkVersion: Function;

export const gameDayStoragePrefix = "DD-result-";
export const hardModeStoragePrefix = "DD-hard-mode-";
export const guessesDayStoragePrefix = "DD-guesses-";

const eventKey = (practice 
  ? "Unlimited "
  : "Day "
) + currentSeed.toString();

function useLocalStorage<T>(
  key: string,
  initial: T
): [T, (value: T | ((t: T) => T)) => void] {
  const [current, setCurrent] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initial;
    } catch (e) {
      return initial;
    }
  });
  const setSetting = (value: T | ((t: T) => T)) => {
    try {
      const v = value instanceof Function ? value(current) : value;
      setCurrent(v);
      window.localStorage.setItem(key, JSON.stringify(v));
    } catch (e) {}
  };
  return [current, setSetting];
}

function useLocalStorageWriteImmediately<T>(
  key: string,
  initial: T
): [T, (value: T | ((t: T) => T)) => void] {
  const [current, setCurrent] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (!item) {
        window.localStorage.setItem(key, JSON.stringify(initial));
      }
      return item ? JSON.parse(item) : initial;
    } catch (e) {
      window.localStorage.setItem(key, JSON.stringify(initial));
      return initial;
    }
  });
  const setSetting = (value: T | ((t: T) => T)) => {
    try {
      const v = value instanceof Function ? value(current) : value;
      setCurrent(v);
      window.localStorage.setItem(key, JSON.stringify(v));
    } catch (e) {}
  };
  return [current, setSetting];
}

function randomBindings(random: ()=>number): LetterBindings {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.toLowerCase().split('');
  const pairings: LetterBindings = [];

  const twoVowels = [
    "ae", "ai", "ao", "au", "ay",
    "ei", "eo", "eu", "ey",
    "io", "iu", "iy",
    "ou", "oy"
  ];

  const blendSubstitutes = [
    "lr", "mn", "ct", "bf", "ps", "gd", "hk"
  ];

  const startingBlends = [
    "bl", "cl", "fl", "gl", "pl", "sl", 
    "br", "cr", "dr", "fr", "gr", "pr", "tr",
    "sm", "sn", "sp", "sc", "st", "sw",
  ];

  const endingBlends = [
    "ct", "ft", "lt", "nd", "nt", "mp", "nk", "ng", "sk", "st", "rd", "ld", "lp", "pt"
  ];

  const mostCommonLetterPairs = [
    "ar", "be", "ch", "de", "er",
    "fi", "ge", "ha", "in", "jo",
    "ke", "la", "ma", "ne", "or",
    "pe", "qu", "re", "st", "te",
    "us", "ve", "wa", "xi", "yl",
    "ze"
  ];

  const lettersByFrequency = "earotilsnucyhdpgmbfkwvxzqj";

  const oneCommonOneUncommon = function() : string[]{
    const mostCommon = lettersByFrequency.slice(0,13);
    const leastCommon = lettersByFrequency.slice(13);
    let result : string[] = [];
    for (let i = 0; i < mostCommon.length; i++) {
      for (let j = 0; j < leastCommon.length; j++) {
        if (mostCommon[i] === leastCommon[j]) continue;
        result.push(mostCommon[i] + leastCommon[j]);
      }
    }
    return result;
  }();

  const vowels = "aeiou";
  const consonants = "bcdfghjklmnpqrstvwxyz";

  const vowelConsonant = function() : string[] {
    let result : string[] = [];
    for (let i = 0; i < vowels.length; i++) {
      for (let j = 0; j < consonants.length; j++) {
        result.push(vowels[i] + consonants[j]);
      }
    }
    return result;
  }();

  const twoUncommonLetters = [
    "jq", "jz", "jx", "jw", "jv",
    "qz", "qx", "qw", "qv",
    "zx", "zw", "zv",
    "xw", "xv",
    "wv"
  ];

  const samples : [string[], number][] = [
    [oneCommonOneUncommon,3],
    [vowelConsonant, 3],
    [[...startingBlends,...endingBlends], 6],  
    [mostCommonLetterPairs,0],
    [twoVowels, 0],
    [blendSubstitutes, 0],
    [twoUncommonLetters, 0],
  ];

  for (const [ambiguous, count] of samples) {
    let numSampled = 0;
    while(ambiguous.length > 0 && numSampled < count) {
      
      const randomIndex = Math.floor(random() * ambiguous.length);
      const pair = ambiguous.splice(randomIndex, 1)[0];

      if(letters.indexOf(pair[0]) === -1 || letters.indexOf(pair[1]) === -1)
        continue;

      if(pair[0] < pair[1]) {
        pairings.push(pair)
      } else {
        pairings.push(pair[1]+pair[0]);
      }

      letters.splice(letters.indexOf(pair[0]), 1);
      letters.splice(letters.indexOf(pair[1]), 1);
      ++numSampled;
    }
  }

  while (letters.length > 0) {
    const randomIndex1 = Math.floor(random() * letters.length);
    const letter1 = letters.splice(randomIndex1, 1)[0];

    const randomIndex2 = Math.floor(random() * letters.length);
    const letter2 = letters.splice(randomIndex2, 1)[0];

    pairings.push(letter1 < letter2 ? letter1+letter2 : letter2+letter1);
  }

  return pairings;
}

function gameOverText(state: GameState, target: string) : string {
  const verbed = state === GameState.Won ? "won!" : "lost.";
  return `You ${verbed} The word was ${target.toUpperCase()}.`; 
}

export async function copyImportCode() {
  try {
    await navigator.clipboard.writeText(serializeStorage());
    return;
  } catch (e) {
    console.warn("navigator.clipboard.writeText failed:", e);
  }
}

function loadPuzzle(seed: number) : Puzzle|undefined {
  let hardCoded = hardCodedPuzzles[seed];
  if (hardCoded && !practice) {
    if (full_dictionary.includes(hardCoded.target) ) {
      return hardCoded;
    }
    else {
      window.console.log("ERROR: " + hardCoded.target);
    }
  }
  hardCoded = hardCodedPractice[seed];
  if (hardCoded && practice) {
    if (full_dictionary.includes(hardCoded.target) ) {
      return hardCoded;
    }
    else {
      window.console.log("ERROR: " + hardCoded.target);
    }
  }
  return undefined;
}

function randomTarget(random: ()=>number, eligible: string[]): string {
  let candidate = pick(eligible, random);
  while (/\*/.test(candidate)) {
    candidate = pick(eligible, random);
  }
  return candidate;
}

let uniqueGame = practice ? 400000 : 4000;
export function makePuzzle(seed: number) : Puzzle { 
  let loadedPuzzle = loadPuzzle(seed);
  if (loadedPuzzle) {
    return loadedPuzzle;
  }
  let random = makeRandom(seed+uniqueGame);

  let wordLength = 5;

  const eligible = full_targets
    .slice(0, full_targets.indexOf("murky") + 1)
    .filter((word: string) => word.length == wordLength);

  let target = randomTarget(random, eligible);
  let bindings = randomBindings(random);
  let puzzle: Puzzle = {
    target: target,
    bindings: bindings,
  };
  return puzzle;
}

export function cluesEqual(clue1: CluedLetterPair[], clue2: CluedLetterPair[]) {
  if (clue1.length != clue2.length ) {
    return false;
  }
  for(var i = 0; i < clue1.length; ++i ) {
    if(clue1[i].clue != clue2[i].clue ) {
      return false;
    }
  }
  return true;
}

export function emojiBlock(day: Day, colorBlind: boolean) : string {
  const emoji = colorBlind
    ? ["⬛", "🟦", "🟧"]
    : ["⬛", "🟨", "🟩"];
  return day.guesses.map((guess) =>
        clue(guess, day.puzzle.bindings, day.puzzle.target)
          .map((c) => emoji[c.clue ?? 0])
          .join("")
      )
      .join("\n");
}

export type LetterBindings = string[];

export function boundWord(word: string, bindings: LetterBindings): string {
  const letterMap = new Map<string, string>(bindings.map(pair => pair.split('') as [string, string]));
  const reverseLetterMap = new Map<string, string>(Array.from(letterMap.entries()).map(([key, value]) => [value, key]));

  let boundWord = '';

  for (const letter of word.toLowerCase()) {
    const pairedLetter = letterMap.get(letter) || reverseLetterMap.get(letter);

    if (pairedLetter) {
      boundWord += (letter < pairedLetter) ? (letter + pairedLetter) : (pairedLetter + letter);
    } else {
      throw new Error(`Invalid letter: ${letter}`);
    }
  }

  return boundWord;
}

export function boundPairs(str: string) {
  let result : string[] = []
  for (let i = 0; i < str.length; i += 2) {
    result =  [...result, ("" + str[i] + str[i+1])];
  }
  return result;
}

export function boundSlices(str: string): { lefts: string; rights: string } {
  let evenResult = '';
  let oddResult = '';

  for (let i = 0; i < str.length; i++) {
    if (i % 2 === 0) {
      evenResult += str[i];
    } else {
      oddResult += str[i];
    }
  }
  return {
    lefts: evenResult,
    rights: oddResult,
  };
}

export interface Puzzle {
  target: string,
  bindings: LetterBindings,
}

interface GameProps {
  maxGuesses: number;
  hidden: boolean;
  colorBlind: boolean;
  keyboardLayout: string;
  hardMode: boolean;
}

function Game(props: GameProps) {

  if (isDev && urlParam("export")) {
    let values : Record<number, Puzzle> = {};    
    for(let i = 1; i <= parseInt(urlParam("export") ?? "1"); ++i) {
      if (practice)
        continue;
      values[i] = makePuzzle(i);
    }
    window.console.log( JSON.stringify(values, null, "\t") );
  }
  const resetDay = () => {
    if (isDev) {
      window.localStorage.removeItem(gameDayStoragePrefix+dayNum);
      window.localStorage.removeItem(hardModeStoragePrefix+dayNum);
      window.localStorage.removeItem(guessesDayStoragePrefix+dayNum);
    }
  }

  const resetPractice = () => {
    window.localStorage.removeItem("practice");
    window.localStorage.removeItem("practiceState");
    window.localStorage.removeItem("practiceHardMode");
    window.localStorage.removeItem("practiceGuesses");
  }

  if (needResetPractice) {
    resetPractice();
  }

  const [puzzle, setPuzzle] = useState(() => {
    let p = makePuzzle(currentSeed);
    return p;
  });
  
  const lengthTargets = useMemo(() => {
    return full_targets.filter((word: string) => word.length == puzzle.target.length)
  }, [puzzle]);

  const lengthDictionary = useMemo(() => {
    return full_dictionary.filter((word: string) => word.length == puzzle.target.length);
  }, [puzzle]);

  const targetListLookup = useMemo(() => {
    return lengthDictionary
    .reduce((lookup:Record<string,number>, word) => {
      let index = lengthTargets.indexOf(word);
      lookup[word] = index === -1 ? lengthTargets.length : index;
      return lookup;
    }, {})
  }, [puzzle, lengthDictionary, lengthTargets]);

  const boundDictionary = useMemo(() => {
    console.log("making BD for length " + puzzle.target.length);
    return lengthDictionary
      .map((word: string) => boundWord(word, puzzle.bindings));
  }, [puzzle, lengthDictionary, lengthTargets]);

  const boundLookup = useMemo(() => {
    let lookup = new Map<string, string[]>();
    for (let i = 0; i < lengthDictionary.length; i++) {
      let entry = lengthDictionary[i];
      let boundEntry = boundDictionary[i];
      let entries = lookup.get(boundEntry);
      if (!entries) {
        entries = [];
      }
      let insertIndex = entries.findIndex((existingEntry: string) => {
        const entryIndex = targetListLookup[entry];
        const existingEntryIndex = targetListLookup[existingEntry];
        if (entryIndex !== undefined && existingEntryIndex !== undefined) {
          return entryIndex < existingEntryIndex;
        }
        return false;
      });
      if (insertIndex === -1) {
        entries.push(entry);
      } else {
        entries.splice(insertIndex, 0, entry);
      }  
      lookup.set(boundEntry, entries);
    }
    return lookup;
  }, [boundDictionary, puzzle, lengthDictionary, lengthTargets, targetListLookup]);

  let hardModeStorageKey = practice ? ("practiceHardMode") : (hardModeStoragePrefix+currentSeed);
  let stateStorageKey = practice ? ("practiceState") : (gameDayStoragePrefix+currentSeed);
  let guessesStorageKey = practice ? ("practiceGuesses") : (guessesDayStoragePrefix+currentSeed);

  const [gameState, setGameState] = useLocalStorage<GameState>(stateStorageKey, GameState.Playing);
  const [hardModeState, setHardModeState] = useLocalStorageWriteImmediately<boolean>(hardModeStorageKey, props.hardMode);
  const [guesses, setGuesses] = useLocalStorage<string[]>(guessesStorageKey, new Array(0));
  const [currentGuess, setCurrentGuess] = useState<[string,number]>(["          ",0]);
  const [hint, setHint] = useState<string>(getHintFromState());
  const styles = ["az", "AZ"];
  const [style, setCurrentStyle] = useLocalStorage<string>(styles[0], "az");
  const sorting = ["AZ", "ZA"];
  const [sort, setCurrentSort] = useState<string>(sorting[0]);

  let Goat = (str: string) => {
    GoatEvent(str + (hardModeState ? ", Hard" : ""));
  };  
   
  const tableRef = useRef<HTMLTableElement>(null);
  async function share(copiedHint: string, text?: string) {
    
    if (window.location.protocol === "http:") {
      if (window.confirm(
        "Only secure websites (https, not http) can access the clipboard/sharing."
        + " Would you like to switch to the https site?"
        + " Note that game history is separate between http and https and you may need to update your bookmark."
      )) {
        window.location.protocol = "https:";
        return;
      }
    }

    const url = window.location.origin + window.location.pathname + (practice ? ("?unlimited=" + currentSeed.toString()) : "");
    const body = (text ? text + "\n" : "") + url;
    if (
      /android|iphone|ipad|ipod|webos/i.test(navigator.userAgent) &&
      !/firefox/i.test(navigator.userAgent)
    ) {
      try {
        await navigator.share({ text: body });
        return;
      } catch (e) {
        console.warn("navigator.share failed:", e);
      }
    }
    try {
      await navigator.clipboard.writeText(body);
      setHint(copiedHint);
      return;
    } catch (e) {
      console.warn("navigator.clipboard.writeText failed:", e);
    }
    setHint(url);
  }

  function getHintFromState() {    
    if  (gameState === GameState.Won || gameState === GameState.Lost) {
      return gameOverText(gameState, puzzle.target);
    }
    return "";
  }

  const onKey = (key: string) => {
    if (gameState !== GameState.Playing) {
      return;
    }

    const realMaxGuesses = props.maxGuesses;
  
    if (guesses.length === realMaxGuesses) {
      return;
    }
    if (/^[a-z]$/i.test(key)) {
      setCurrentGuess(([guess,cursor]) => {
        if ( cursor == -1 ) return [guess,cursor];
          return [
            (guess.slice(0,cursor*2) + boundWord(key,puzzle.bindings).toLowerCase() 
              + guess.slice(cursor*2+2)).slice(0, puzzle.target.length*2), 
              cursor == (puzzle.target.length-1) ? -1 : cursor+1]
        }        
      );
      tableRef.current?.focus();
      setHint(getHintFromState());
    } else if (key === "Backspace") {
      setCurrentGuess(([guess,cursor]) => {
        let realCursor = cursor == -1 ? puzzle.target.length : cursor;
        if (cursor != -1 && guess[cursor] != "  " ) {
          // delete at the cursor and back up if the cursor is currently on a typed letter
          return [(guess.slice(0,cursor*2) + "  " + guess.slice(cursor*2+2)).slice(0,puzzle.target.length*2),Math.max(0,realCursor-1)];
        }
        // if the cursor is on a space, delete behind the cursor and back up
        return [(guess.slice(0,realCursor*2-2) + "  " + guess.slice(realCursor*2)).slice(0, puzzle.target.length*2),Math.max(0,realCursor-1)]
      });
      setHint(getHintFromState());
    } else if (key === "Style") {
      setCurrentStyle((style) => styles[(styles.indexOf(style) + 1) % styles.length]);
    } else if (key === "Sort") {
      setCurrentSort((sort) => sorting[(sorting.indexOf(sort) + 1) % sorting.length]);
    } else if (key == "SpaceBar") {
      setCurrentGuess(([guess,cursor]) => {
        if ( cursor == -1 ) return [guess,cursor];
        return [(guess.slice(0,cursor*2) + "  " + guess.slice(cursor*2+2)).slice(0, puzzle.target.length*2), 
          cursor == (puzzle.target.length-1) ? -1 : cursor+1]
      });
    } else if (key === "ArrowRight") {
      setCurrentGuess(([guess,cursor]) => [guess,cursor == (puzzle.target.length-1) ? 0 : cursor+1]);
    } else if (key === "ArrowLeft") {
      setCurrentGuess(([guess,cursor]) => [guess,cursor == -1 ? (puzzle.target.length-1) : cursor == 0 ? (puzzle.target.length-1) : cursor-1]);
    } else if (key === "Delete") {
      setCurrentGuess(([guess,cursor]) => [(guess.slice(0,cursor*2) + "  " + guess.slice(cursor*2+2)).slice(0,puzzle.target.length*2),cursor]);
    } else if (key === "Enter") {
      if (currentGuess[0].length !== puzzle.target.length*2 || currentGuess[0].lastIndexOf("  ") !== -1) {
        setHint("Type more letters");
        return;
      }
      if(guesses.includes(currentGuess[0])) {
        setHint("You've already guessed that");
        return;
      }
      if (!boundDictionary.includes(currentGuess[0])) {
        Goat("Nonword: " + currentGuess);
        setHint(`Your guess must contain some valid word.`);
        return;
      }
      Goat("Guess " + (guesses.length+1) + ": " + currentGuess);
      setGuesses((guesses) => guesses.concat([currentGuess[0]]));
      setCurrentGuess(["          ",0]);
      speak(describeClue(clue(currentGuess[0], puzzle.bindings, puzzle.target)))
      doWinOrLose();
    }
  };

  const doWinOrLose = () => {
    if ( gameState == GameState.Playing ) {      
      if ( (guesses.includes(boundWord(puzzle.target,puzzle.bindings))) ) {
        setGameState(GameState.Won);
        Goat("Won: " + eventKey + ", " + guesses.length + " guesses");
      } else if (guesses.length >= props.maxGuesses) {
        setGameState(GameState.Lost);
        Goat("Lost: " + eventKey);
      } 
    } 
    setHint(getHintFromState());
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        onKey(e.key);
      }
      if (!e.altKey && ["Backspace", "Delete", "SpaceBar", "ArrowLeft", "ArrowRight", "  "].lastIndexOf(e.key) !== -1 && gameState == GameState.Playing) {
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [currentGuess, gameState]);

  useEffect(() => {
    doWinOrLose();
  }, [currentGuess, gameState, guesses, puzzle.target]);

  useEffect(() => {
    if ( gameState == GameState.Playing && guesses.length == 0 && currentGuess[0].length == 0 ) {
      Goat("Starting: " + eventKey);
    }
  }, [currentSeed]);
  
  const realMaxGuesses = gameState == GameState.Won 
    ? guesses.length 
    : Math.max(guesses.length, props.maxGuesses);

  let letterInfo = new Map<string, [Clue, number]>();

  const cluedRows = Array(realMaxGuesses)
    .fill(undefined)
    .map((_,i)=>{
      const guess = [...guesses, currentGuess[0]][i] ?? "";
      return clue(guess, puzzle.bindings, puzzle.target); 
    });

  const tableRows = Array(realMaxGuesses)
    .fill(undefined)
    .map((_, i) => {      
      const cluedLetters = cluedRows[i];
      const lockedIn = i < guesses.length;
      const guess = guesses[i];
      const possibleWords = boundLookup.get(guess) ?? [];
      const annotation = possibleWords.length > 0
        ? possibleWords.join(" ")
        : "\u00a0";
      if (lockedIn) {
        for (const { clue, letterPair } of cluedLetters) {
          if (clue === undefined) break;
          const count = cluedLetters
            .filter((cl) => cl.letterPair === letterPair && cl.clue && cl.clue > Clue.Absent)
            .length;
          const old = letterInfo.get(letterPair);
          const newCount = old === undefined || old[1] < count ? count : old[1];
          const newClue = old === undefined || old[0] < clue ? clue : old[0]; 
          letterInfo.set(letterPair, [newClue, newCount]);
        }
      }
      return (
        <Row
          onClick={(rowNumber, column) => {
            setCurrentGuess([currentGuess[0],column])
          }}
          currentGuess={currentGuess}
          key={i}         
          rowState={
            lockedIn
              ? RowState.LockedIn
              : (i === guesses.length)
              ? RowState.Editing
              : RowState.Pending
          }
          cluedRows={cluedRows.slice(undefined, guesses.length)}
          cluedLetters={cluedLetters}
          letterInfo={letterInfo}
          rowNumber={i}
          annotation={annotation}
          rowWidth={puzzle.target.length}
        />
      );
    });
    
  const dateText = `, ${new Date(day1Date.getTime()+(dayNum-1)*86400*1000).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}`;
  const cheatText = cheat ? ` ${puzzle.target}` : "";
  const canPrev = dayNum > 1;
  const canNext = dayNum < todayDayNum || isDev;
  const practiceLink = "?unlimited";
  const prevLink = "?x=" + (dayNum-1).toString() + (isDev ? "&xyzzyx="+cheatyface["password"] : "") + (cheat ? "&cheat=1" : "") + (urlParam("preventRedirect") !== null ? "&preventRedirect" : "");
  const nextLink = "?x=" + (dayNum+1).toString() + (isDev ? "&xyzzyx="+cheatyface["password"] : "") + (cheat ? "&cheat=1" : "") + (urlParam("preventRedirect") !== null ? "&preventRedirect" : "");

  const newsIndex = 9;
  const [readNewsItem, setReadNewsItem] = useLocalStorageWriteImmediately<number>("read-news-item", 0);
  const news: string = "";
  const showNews = news != "" && readNewsItem < newsIndex && RawStats().wins >= 1 && gameState == GameState.Playing;
  const hardModeIndicator = hardModeState ? " !!!" : "";
  
  return (
    <div className="Game" style={{ display: props.hidden ? "none" : "block" }}>
      {showNews && (<PopUp text={news} title={"News!"} onClick={ ()=>{setReadNewsItem(newsIndex); } }/>) }
      <div className="Game-options">
        {!practice && canPrev && <span><a className="NextPrev" href={prevLink}>«</a> </span>}
        {!practice && !canPrev && <span> <a className="NextPrev">&nbsp;</a></span>}
        {!practice && <span className="DayNum">Day {dayNum}{`${cheatText}`}{`${dateText}`}</span>}
        {!practice && canNext && <span> <a className="NextPrev" href={nextLink}>»</a></span>}
        {!practice && !canNext && <span> <a className="NextPrev">&nbsp;</a></span>}
        {isDev && <span>| <a href={window.location.href} onClick={ ()=>{resetDay();} }>Reset</a></span>}
        {practice && <span>{`${cheatText}`}</span>}
        {practice && <span>
          <a href=""
            className="practiceLink"
            onClick={(e) => {
              const score = gameState === GameState.Lost ? "X" : guesses.length;
              share(
                "Challenge link copied to clipboard!",
                ``
              );
              e.preventDefault();
            }}
          >
            Share
          </a>        
          <a href={practiceLink} className="practiceLink" onClick={ ()=>{resetPractice();} }> +New </a>
          </span>
        }
      </div>
      <table
        className="Game-rows"
        tabIndex={0}
        aria-label="table of guesses"
        ref={tableRef}
      >
        <tbody>{tableRows}</tbody>
      </table>
      <div
        role="alert"
        style={{
          userSelect: /https?:/.test(hint) ? "text" : "none",
          whiteSpace: "pre-wrap",
        }}
      >
        {(hint || `\u00a0`)}
        {gameState !== GameState.Playing && (
          <p>
          <button
            onClick={() => {
              const score = gameState === GameState.Lost ? "X" : guesses.length.toString();
              share(
                "Result copied to clipboard!",
                `${gameName} ${practice ? ("unlimited " + currentSeed.toString()) : ("#"+dayNum.toString())}${hardModeIndicator} ${score}/${props.maxGuesses}\n` +
                emojiBlock({guesses:guesses, puzzle:puzzle, gameState:gameState, hardMode:hardModeState}, props.colorBlind)
              );
            }}
          >
            Share
          </button>
          </p>
        )}      
      </div>
 
      {readOnly() && (
        <p>
        <button onClick={() => {copyImportCode();}} >
          Copy import code
        </button>
        </p>
      )}
      {!readOnly() && (
        <Keyboard
          layout={props.keyboardLayout}
          letterPairStyle={style}
          letterSort={sort}
          letterInfo={letterInfo}
          onKey={onKey}
          bindings={puzzle.bindings}
        />)}
    </div>
  );
}

export default Game;
