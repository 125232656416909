import { Clue, clueClass, CluedLetterPair, clueWord } from "./clue";

export enum RowState {
  LockedIn,
  Editing,
  Pending,
}

interface RowProps {
  rowState: RowState;
  cluedLetters: CluedLetterPair[];
  letterInfo: Map<string, [Clue, number]>;
  rowNumber: number;
  cluedRows: CluedLetterPair[][];
  annotation?: string;
  currentGuess: [string,number];
  rowWidth: number;
  onClick: (rowNumber: number, letterIndex: number) => void;
}

export function Row(props: RowProps) {
  const isLockedIn = props.rowState === RowState.LockedIn;
  const isEditing = props.rowState === RowState.Editing;
  const letterDivs = props.cluedLetters
    .concat(Array(props.rowWidth).fill({ clue: Clue.Absent, letterPair: "" }))
    .slice(0, props.rowWidth)
    .map(({ clue, letterPair }, i) => {
      let letterClass = "Row-letter";
      if (isLockedIn && clue !== undefined) {
        letterClass += " " + clueClass([clue,0]);
      }
      if(isEditing) 
        letterClass += " Row-editing";{
      }
      if (isEditing && i === props.currentGuess[1]) {
        letterClass += " Row-cursor";
        for(let prevClue of props.cluedRows) {
          if (prevClue.length <= i )
            continue;
          if (prevClue[i].clue === Clue.Correct) {
            letterClass += " Row-cursor-below-known-correct";
          }
        }
      }
      let knownInfo : Clue | undefined;
      if (!isLockedIn && letterPair !== "") {
        const ourInfo = props.letterInfo.get(letterPair);
        if ( ourInfo && ourInfo[0] === Clue.Absent ) {
          knownInfo = Clue.Absent;
        } else {
          for(let prevClue of props.cluedRows) {
            if (prevClue.length <= i )
              continue;
            if ( prevClue[i].letterPair === letterPair ) {
              if (prevClue[i].clue === Clue.Absent ) {
                knownInfo = Clue.Absent;
              } else if (prevClue[i].clue === Clue.Correct && knownInfo !== Clue.Absent) {
                knownInfo = Clue.Correct;
              } else if (prevClue[i].clue === Clue.Elsewhere && knownInfo === undefined) {
                knownInfo = Clue.Elsewhere;
              } 
            }
          }       
        }
      }

      if (knownInfo === Clue.Elsewhere ) {
        letterClass += " Typing-known-elsewhere";
      } else if (knownInfo === Clue.Absent ) {
        letterClass += " Typing-known-absent";
      } else if (knownInfo === Clue.Correct ) {
        letterClass += " Typing-known-correct";
      }

      return (<td
          key={i}
          className={letterClass}
          onClick={isEditing ? ()=>{props.onClick(props.rowNumber, i)} : undefined}
          aria-live={isEditing ? "assertive" : "off"}
          aria-label={
            isLockedIn
              ? letterPair.toUpperCase() +
                (clue === undefined ? "" : ": " + clueWord(clue))
              : ""
          }>
          {(<span onClick={isEditing ? ()=>{props.onClick(props.rowNumber,i)} : undefined}>{letterPair}</span>)}
          </td>);
    });
  let rowClass = "Row";
  if (isLockedIn) rowClass += " Row-locked-in";
  return (
    <tr className={rowClass}>
      {letterDivs}
      {props.annotation &&  (
      <td className="Row-annotation">
        {props.annotation.toUpperCase()}
      </td>)} 
    </tr>
  );
}
