import { Puzzle } from "./Game";

export const hardCodedPuzzles: Record<number, Puzzle> = {
	"1": {
		"target": "navel",
		"bindings": [
			"jo",
			"lq",
			"uw",
			"an",
			"es",
			"it",
			"pr",
			"dk",
			"hx",
			"bm",
			"fv",
			"cy",
			"gz"
		]
	},
	"2": {
		"target": "steel",
		"bindings": [
			"oq",
			"ej",
			"lw",
			"ar",
			"it",
			"su",
			"mp",
			"kn",
			"hy",
			"cg",
			"bf",
			"dz",
			"vx"
		]
	},
	"3": {
		"target": "swear",
		"bindings": [
			"aw",
			"qr",
			"ju",
			"es",
			"in",
			"ot",
			"lp",
			"dx",
			"mv",
			"kz",
			"fy",
			"bg",
			"ch"
		]
	},
	"4": {
		"target": "oasis",
		"bindings": [
			"iw",
			"ax",
			"qt",
			"os",
			"el",
			"ru",
			"kn",
			"mp",
			"dg",
			"hv",
			"cy",
			"fz",
			"bj"
		]
	},
	"5": {
		"target": "pixel",
		"bindings": [
			"ex",
			"js",
			"uv",
			"it",
			"lo",
			"ar",
			"mp",
			"dn",
			"cw",
			"bq",
			"gz",
			"hk",
			"fy"
		]
	},
	"6": {
		"target": "ratio",
		"bindings": [
			"lz",
			"iw",
			"ux",
			"et",
			"os",
			"ar",
			"dn",
			"mp",
			"cg",
			"jq",
			"hk",
			"by",
			"fv"
		]
	},
	"7": {
		"target": "curry",
		"bindings": [
			"ej",
			"sx",
			"iw",
			"at",
			"lo",
			"nu",
			"br",
			"mp",
			"hz",
			"fk",
			"cg",
			"dy",
			"qv"
		]
	},
	"8": {
		"target": "graph",
		"bindings": [
			"jo",
			"ax",
			"rv",
			"el",
			"tu",
			"is",
			"kn",
			"mp",
			"bh",
			"fz",
			"qw",
			"cg",
			"dy"
		]
	},
	"9": {
		"target": "laugh",
		"bindings": [
			"jr",
			"eq",
			"tw",
			"os",
			"in",
			"al",
			"mp",
			"hv",
			"cz",
			"ku",
			"bx",
			"gy",
			"df"
		]
	},
	"10": {
		"target": "valet",
		"bindings": [
			"ow",
			"sx",
			"aq",
			"ru",
			"en",
			"il",
			"mp",
			"ft",
			"bj",
			"gz",
			"dh",
			"ck",
			"vy"
		]
	},
	"11": {
		"target": "width",
		"bindings": [
			"sx",
			"ew",
			"jt",
			"in",
			"or",
			"al",
			"mp",
			"gk",
			"vy",
			"fh",
			"bu",
			"qz",
			"cd"
		]
	},
	"12": {
		"target": "mamma",
		"bindings": [
			"qt",
			"lz",
			"ij",
			"su",
			"or",
			"an",
			"mp",
			"hx",
			"bd",
			"cf",
			"vy",
			"ek",
			"gw"
		]
	},
	"13": {
		"target": "urine",
		"bindings": [
			"lz",
			"nx",
			"ju",
			"et",
			"or",
			"as",
			"mp",
			"gk",
			"bv",
			"iq",
			"cd",
			"fw",
			"hy"
		]
	},
}